



































































import { Component } from 'vue-property-decorator';
import TeachersToolbar from './TeachersToolbar.vue';

@Component
export default class TeachersBottomToolbar extends TeachersToolbar {
}
